import mainjson from '../config/main';
export default function GetData() {
        return {
        "apitype":"coupons",
        "id":"coupon_id",
        "subidname":"coupon_coupongroup_id",
        "options":["view","edit","insert"],
        "nameSingle":"coupon",
        "nameMultiple":"coupons",
        "filters":
            [
                {
                    "key":"state",
                    "name":"State",
                    "options":[
                        {key:"available",value:"Available"},
                        {key:"used",value:"Used"}
                    ]
                }
            ]
        ,
        "fields":{
            "field1":{
                "name":"Text",
                "field":"coupon_text",
                "type":"TextInput",
                "required":true,
                "list":true,
            },
            "field2":{
                "name":"Image",
                "field":"coupon_image",
                "type":"ImageUpload",
                "required":false,
                "list":true,
            },
            "field4":{
                "name":"State",
                "field":"coupon_state",
                "type":"DropDown",
                "options": {
                    "available":"Available",
                    "used":"Used",
                },
                "list":true,
                "required":true,

            }
        }
    }
}